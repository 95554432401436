/* [PAGE] About */

import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip } from 'chart.js';
import { Radar, getElementAtEvent } from "react-chartjs-2";

import Header from "./header.js";
import Footer from "./footer.js";

// CSS
import "../styles/skills.css";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip
);

function RadarChart(props) {
    const chartRef = useRef(null);

    const data = {
        labels: props.langs ? props.langs.map(item => item.name) : [],
        datasets: [
            {
                data: props.langs ? props.langs.map(item => item.level) : [],
                backgroundColor: "rgba(34, 202, 236, 0.2)",
                borderColor: "rgba(34, 202, 236, 1)",
                borderWidth: 1,
                pointBackgroundColor: "rgba(34, 202, 236, 1)",
                pointBorderColor: "rgba(34, 202, 236, 1)",
                pointRadius: 4,
                pointHoverRadius: 6,
            },
        ],
    };
  
    const options = {
        scales: {
            r: {
                min: 0,
                max: 5,
                ticks: { stepSize: 1 },
                grid: { circular: true },
                pointLabels: {
                    font: {
                        family: "roboto",
                        size: 16,
                    },
                }
            }
        },
        plugins: {
            tooltip: {
                titleFont: {
                    family: "roboto-bold",
                    size: 14,
                },
                bodyFont: {
                    family: "roboto",
                    size: 14,
                },
                callbacks: {
                    label: tooltipItem => "Click to see details..."
                }
            }
        }
    };

    function handleClick(event) {
        if (!chartRef) return;
        const chart = chartRef.current;
        if (!chart) return;

        const elements = getElementAtEvent(chart, event);
        if (elements.length > 0) {
            const index = elements[0].index;
            props.onLangSelected(index);
        }
    };
  
    return (
        <div className="radar-chart-box">
            <Radar ref={chartRef} data={data} options={options} onClick={handleClick} />
        </div>
    );
};

function DetailsPanel(props) {
    const texts = props.text.map((item, index) => <div key={index} className="text">{item}</div>);
    return (
        <div className="details-panel-box">
            <div className="details-panel">
                <div className="title">{props.title}</div>
                {texts}
            </div>
        </div>
    );
}

function SkillsPage(props) {
    const [langs, setLangs] = useState(null);
    const [title, setTitle] = useState(null);
    const [text, setText] = useState([]);
    const [index, setIndex] = useState(null);
    const contentRef = useRef(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_SERVER_URL + "/index/skills")
            .then(data => data.json())
            .then(data => setLangs(data));
    }, []);

    function onLangSelected(newIndex) {
        // If clicked on the same language, hide the details panel
        if (newIndex === index) {
            setIndex(null);
            if (contentRef) contentRef.current.style.width = "500px";
            return;
        }

        // Otherwise, display the corresponding info
        setTitle(langs[newIndex].name);
        setText(langs[newIndex].description);
        setIndex(newIndex);

        // Unhide the details panel
        if (contentRef) contentRef.current.style.width = "1100px";
    }

    return (
        <div id="skills">
            <Header blocks={props.blocks} current={1} />
            <div ref={contentRef} className="content">
                <RadarChart langs={langs} onLangSelected={onLangSelected} />
                <DetailsPanel title={title} text={text}  />
            </div>
            <Footer />
        </div>
    );
}

export default SkillsPage;
