/* [PAGE] Projects */

import React from "react";
import { NavLink as Link } from "react-router-dom";
import Path from "path-browserify";

import Header from "./header";
import Footer from "./footer";

// CSS
import "../styles/projects.css";

function ProjectBox(props) {
    const coverImgSrc = `${process.env.REACT_APP_SERVER_URL}/file?` + new URLSearchParams({
        path: Path.join("projects", props.projectInfo.cover_img_path)
    });

    return (
        <Link to={"/project/" + props.projectInfo.id}>
            <div className="project-box">
                <div className="image">
                    <img
                        src={coverImgSrc}
                        alt={props.projectInfo.id}
                    />
                </div>
                <div className="text">
                    <div className="name">{props.projectInfo.name}</div>
                    <div className="date">{props.projectInfo.date}</div>
                </div>
            </div>
        </Link>
    );
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/index/projects`)
            .then(data => data.json())
            .then(data => this.setState({
                content: data,
            }));
    }

    render() {
        let projects = this.state.content.map((projectInfo, index) => {
            return (
                <ProjectBox
                    key={index}
                    projectInfo={projectInfo}
                />
            );
        });

        return (
            <section className="content">
                <div className="content-table">
                    {projects}
                </div>
            </section>
        );
    }
}

class Intro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
        }
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/readfile?` + new URLSearchParams({
            path: "projects/intro.html",
        }))
            .then(data => data.text())
            .then(data => data.trim())
            .then(data => this.setState({
                text: data,
            }));
    }

    render() {
        return (
            <section className="intro">
                <p dangerouslySetInnerHTML={{__html: this.state.text}}></p>
            </section>
        );
    }
}

function ProjectsPage(props) {
    return (
        <div id="projects">
            <Header blocks={props.blocks} current={3} />
            <div className="intro-and-content">
                <Intro />
                <Content />
            </div>
            <Footer />
        </div>
    );
}

export default ProjectsPage;
