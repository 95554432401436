import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";

// Pages
import HomePage from "./components/home.js";
import SkillsPage from "./components/skills.js";
import ResumePage from "./components/resume.js";
import ProjectsPage from "./components/projects.js";
import ProjectPage from "./components/project.js";
import NotFoundPage from "./components/not-found.js";

// CSS
import "./styles/fonts.css";
import "./styles/index.css";

// Navbar blocks
let blocks = [
    { link: "/", content: "Home", },
    { link: "/skills", content: "Skills", },
    { link: "/resume", content: "Resume", },
    { link: "/projects", content: "Projects", },
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <HttpsRedirect>
            <Router>
                <Routes>
                    <Route exact path="/" element={<HomePage blocks={blocks} />}></Route>
                    <Route exact path="/skills" element={<SkillsPage blocks={blocks} langs={["C", "C++", "Python", "Java", "JavaScript", "SQL"]} />}></Route>
                    <Route exact path="/resume" element={<ResumePage blocks={blocks} />}></Route>
                    <Route exact path="/projects" element={<ProjectsPage blocks={blocks} />}></Route>
                    <Route exact path="/project/:projectId" element={<ProjectPage blocks={blocks} />}></Route>
                    <Route exact path="*" element={<NotFoundPage blocks={blocks} />}></Route>
                </Routes>
            </Router>
        </HttpsRedirect>
    </React.StrictMode>
);
