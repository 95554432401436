// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#skills .content {
    margin: auto;
    width: 500px;
    height: calc(100vh - 310px);
    overflow: hidden;
    transition: width 0.8s ease-in-out;
}

#skills .radar-chart-box {
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: calc(100vh - 310px);
}

#skills .details-panel-box {
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: calc(100vh - 310px);
}

#skills .details-panel {
    width: 500px;
}

#skills .details-panel .title {
    margin: 10px;
    width: calc(100% - 20px);
    line-height: 150%;
    font-family: roboto;
    font-size: 32px;
    font-weight: 700;
}

#skills .details-panel .text {
    margin: 10px;
    width: calc(100% - 20px);
    line-height: 150%;
    font-family: roboto;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/skills.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,wBAAwB;IACxB,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,wBAAwB;IACxB,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":["#skills .content {\n    margin: auto;\n    width: 500px;\n    height: calc(100vh - 310px);\n    overflow: hidden;\n    transition: width 0.8s ease-in-out;\n}\n\n#skills .radar-chart-box {\n    display: flex;\n    float: left;\n    justify-content: center;\n    align-items: center;\n    width: 500px;\n    height: calc(100vh - 310px);\n}\n\n#skills .details-panel-box {\n    display: flex;\n    float: left;\n    justify-content: center;\n    align-items: center;\n    width: 600px;\n    height: calc(100vh - 310px);\n}\n\n#skills .details-panel {\n    width: 500px;\n}\n\n#skills .details-panel .title {\n    margin: 10px;\n    width: calc(100% - 20px);\n    line-height: 150%;\n    font-family: roboto;\n    font-size: 32px;\n    font-weight: 700;\n}\n\n#skills .details-panel .text {\n    margin: 10px;\n    width: calc(100% - 20px);\n    line-height: 150%;\n    font-family: roboto;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
