/* [PAGE] Resume */

import React from "react";

import Header from "./header.js";
import Footer from "./footer.js";

// CSS
import "../styles/resume.css";

function ResumePage(props) {
    const resumeURL = `${process.env.REACT_APP_SERVER_URL}/Resume.pdf`;
    return (
        <div id="resume">
            <Header blocks={props.blocks} current={2} />
            <section className="content">
                <iframe className="pdf-viewer" title="Resume" src={resumeURL}></iframe>
            </section>
            <Footer />
        </div>
    );
}

export default ResumePage;
