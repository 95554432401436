/* [PAGE] Home */

import React from "react";

import Header from "./header.js";
import Footer from "./footer.js";

// CSS
import "../styles/home.css";

class TextBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
        };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_SERVER_URL + "/readfile?" + new URLSearchParams({ path: this.props.textPath, }))
            .then(data => data.text())
            .then(data => this.setState({
                text: data,
            }));
    }

    render() {
        return (
            <td className="text" style={{width: this.props.width}}>
                <div dangerouslySetInnerHTML={{__html: this.state.text}}>
                </div>
            </td>
        );
    }
}

function ImageBlock(props) {
    return (
        <td className="image" style={{width: props.width}}>
            <img
                src={process.env.REACT_APP_SERVER_URL + "/file?" + new URLSearchParams({ path: props.imagePath, })}
                alt="load failed"
            >
            </img>
        </td>
    );
}

function Section(props) {
    // Render all blocks (columns)
    let blocks = props.blocks.map((block, index) => {
        switch (block.type) {
            case "text":
                return (
                    <TextBlock
                        key={index.toString()}
                        width={block.width}
                        textPath={block.path}
                    />
                );
            case "image":
                return (
                    <ImageBlock
                        key={index.toString()}
                        width={block.width}
                        imagePath={block.path}
                    />
                );
            default:
                return null;
        }
    });

    return (
        <tr className="content-row" style={{height: props.height}}>
            {blocks}
        </tr>
    );
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [],
        };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_SERVER_URL + "/index/home")
            .then(data => data.json())
            .then(data => this.setState({
                sections: data,
            }));
    }

    render() {
        // Render all sections (rows)
        let sections = this.state.sections.map((section, index) => {
            return (
                <Section
                    key={index.toString()}
                    height={section.height}
                    blocks={section.contents}
                />
            );
        });

        return (
            <section className="content">
                <table className="content-table">
                    <tbody>
                        {sections}
                    </tbody>
                </table>
            </section>
        );
    }
}

function HomePage(props) {
    return (
        <div id="home">
            <Header blocks={props.blocks} current={0} />
            <Content />
            <Footer />
        </div>
    );
}

export default HomePage;
