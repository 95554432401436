/* [COMPONENT] Footer */

import React from "react";

// CSS
import "../styles/footer.css"

function Infoline(props) {
    return (
        <div className="footer-block-line" dangerouslySetInnerHTML={{__html: props.content}}></div>
    );
}

function Infoblock(props) {
    let lines = props.content.map((line, index) => {
        return (
            <Infoline
                key={index.toString()}
                content={line}
            />
        );
    });

    return (
        <div className="footer-block">
            <div className="footer-block-name">
                {props.name}
            </div>
            <div className="footer-block-content">
                {lines}
            </div>
        </div>
    );
}

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [],
        };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_SERVER_URL + "/index/footer")
            .then(data => data.json())
            .then(data => this.setState({
                info: data,
            }));
    }

    render() {
        let infobar = this.state.info.map((block, index) => {
            return (
                <Infoblock
                    key={index.toString()}
                    name={block.name}
                    content={block.contents}
                />
            );
        });

        return (
            <section id="footer">
                <div id="footer-bar">{infobar}</div>
            </section>
        );
    }
}

export default Footer;
