// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#home .content {
    min-height: calc(100vh - 310px);
}

#home .content-table {
    margin: auto;
    width: 1180px;
    padding: 10px;
}

#home .content-row {
    margin: 20px;
    min-height: 480px;
}

#home .content-row::after {
    content: "";
    clear: both;
    display: table;
}

#home .content-row .text {
    float: left;
    display: flex;
    padding: 0px;
    height: inherit;
    justify-content: center;
    align-items: center;
}

#home .content-row .image {
    float: left;
    display: flex;
    padding: 0px;
    height: inherit;
    justify-content: center;
    align-items: center;
}

#home .content-row .image img {
    height: 400px;
    margin: 20px;
    display: table-cell;
    vertical-align: middle;
}

#home h {
    line-height: 200%;
    font-family: roboto;
    font-size: 32px;
    font-weight: 700;
    color: #222222;
}

#home p {
    line-height: 200%;
    font-family: roboto;
    font-size: 16px;
    color: #222222;
}

#home .highlight {
    color: #004aad;
}
`, "",{"version":3,"sources":["webpack://./src/styles/home.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#home .content {\n    min-height: calc(100vh - 310px);\n}\n\n#home .content-table {\n    margin: auto;\n    width: 1180px;\n    padding: 10px;\n}\n\n#home .content-row {\n    margin: 20px;\n    min-height: 480px;\n}\n\n#home .content-row::after {\n    content: \"\";\n    clear: both;\n    display: table;\n}\n\n#home .content-row .text {\n    float: left;\n    display: flex;\n    padding: 0px;\n    height: inherit;\n    justify-content: center;\n    align-items: center;\n}\n\n#home .content-row .image {\n    float: left;\n    display: flex;\n    padding: 0px;\n    height: inherit;\n    justify-content: center;\n    align-items: center;\n}\n\n#home .content-row .image img {\n    height: 400px;\n    margin: 20px;\n    display: table-cell;\n    vertical-align: middle;\n}\n\n#home h {\n    line-height: 200%;\n    font-family: roboto;\n    font-size: 32px;\n    font-weight: 700;\n    color: #222222;\n}\n\n#home p {\n    line-height: 200%;\n    font-family: roboto;\n    font-size: 16px;\n    color: #222222;\n}\n\n#home .highlight {\n    color: #004aad;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
