/* [PAGE] Project */

import React from "react";
import { useParams } from "react-router-dom";
import stringToJsx from "html-react-parser";

import Header from "./header";
import Footer from "./footer";
import NotFound from "./not-found";

// CSS
import "../styles/project.css";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class MenuItem extends React.Component {
    render() {
        let className = this.props.active ? "menu-item active " : "menu-item ";
        className += this.props.level;
        return (
            <a href={"#" + this.props.hash}><div className={className}>
                {this.props.text}
            </div></a>
        );
    }
}

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.threshold = 40;
        this.state = {
            active: null,
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", () => this.handleScroll());
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", () => this.handleScroll());
    }

    setActive(index) {
        this.setState({
            active: index,
        });
    }

    handleScroll() {
        let article = document.querySelector(".article");
        if (!article) return;
        let headings = Array.from(article.querySelectorAll("h1, h2, h3"));
        if (!headings) return;

        let current = null;
        let curIndex = -1;
        headings.every((heading, index) => {
            const rect = heading.getBoundingClientRect();
            if (rect.top <= this.threshold) {
                current = heading.id;
                curIndex = index;
                return true;
            } else {
                return false;
            }
        });

        // const url = window.location.toString().split('#')[0];
        if (current != null) {
            this.setActive(curIndex);
            // window.history.replaceState(null, null, url + "#" + current);
        } else {
            this.setActive(null);
            // window.history.replaceState(null, null, url);
        }
    }

    render() {
        let menuItems = this.props.menu.map((item, index) => {
            return (
                <MenuItem
                    key={index.toString()}
                    level={item.level}
                    text={item.text}
                    hash={item.hash}
                    active={this.state.active === index}
                />
            );
        });

        return (
            <div className="menu-box"><div className="menu">{menuItems}</div></div>
        );
    }
}

class ProjectContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            content: "",
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/readfile?`
              + new URLSearchParams({ path: `projects/${this.props.projectId}/index.html`, }))
            .then(data => data.text())
            .then(data => data.trim())
            .then(data => {
                let [_menu, _content] = parseMenu(data, this.props.projectId);
                this.setState({
                    menu: _menu,
                    content: _content,
                    active: null,
                });
            });
    }

    render() {
        return (
            <section className="page">
                <Menu menu={this.state.menu} />
                <div className="article">{stringToJsx(this.state.content)}</div>
            </section>
        );
    }
}

class ProjectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: -1,
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/project-exist?`
              + new URLSearchParams({ id: this.props.params.projectId, }))
            .then(data => data.text())
            .then(data => this.setState({
                isValid: parseInt(data),
            }));
    }

    render() {
        if (this.state.isValid === -1) {
            return (
                <div id="project">
                    <Header blocks={this.props.blocks} current={3} />
                    <Footer />
                </div>
            );
        } else if (this.state.isValid === 0) {
            return (
                <NotFound blocks={this.props.blocks} current={3} />
            );
        } else {
            return (
                <div id="project">
                    <Header blocks={this.props.blocks} current={3} />
                    <ProjectContent projectId={this.props.params.projectId} />
                    <Footer />
                </div>
            );
        }
    }
}

function getId(_id, i) {
    if (i === 0) return _id;
    else return _id + "_" + i;
}

function parseMenu(htmlStr, projectId) {
    let dom = document.createElement("html");
    dom.innerHTML = htmlStr;
    let titles = Array.from(dom.querySelectorAll("h1, h2, h3"));

    // Add id to data
    let idstr = new Set();
    titles.forEach(element => {
        // Concat title words to obtain id
        let _id = "_" + element.innerHTML.split(" ").map(substr => {
            return substr.toLowerCase();
        }).join("-");
        _id = encodeURIComponent(_id);

        // Make sure _id is not used before
        let i = 0;
        while (idstr.has(getId(_id, i))) i++;
        idstr.add(getId(_id, i));
        element.id = getId(_id, i);
    });

    // Create menu list
    let menuList = titles.map(element => {
        return {
            level: element.tagName.toLowerCase(),
            text: element.innerHTML,
            hash: element.id,
        };
    });

    // Parse links
    Array.from(dom.querySelectorAll("a")).forEach(element => {
        if (!element.hasAttribute("href")) {
            element.href = element.innerHTML;
        }
    });

    // Parse images
    Array.from(dom.querySelectorAll("img")).forEach(element => {
        let localSrc = element.getAttribute("local-src");
        element.className = "image eighty-width";
        element.alt = localSrc;
        element.src = `${process.env.REACT_APP_SERVER_URL}/file?`
            + new URLSearchParams({ path: `projects/${projectId}/${localSrc}` });
        if (element.width === "0") {
            element.width = "740px";
        }
    });

    return [menuList, dom.querySelector("body").innerHTML];
}

export default withParams(ProjectPage);
