/* [PAGE] 404 */

import React from "react";

import Header from "./header";
import Footer from "./footer";

// CSS
import "../styles/not-found.css";

function Message(props) {
    return (
        <section className="message-box">
            <div className="message">
                <span className="highlight">404</span> Not Found
            </div>
        </section>
    )
}

function NotFoundPage(props) {
    return (
        <div id="not-found">
            <Header blocks={props.blocks} current={props.current} />
            <Message />
            <Footer />
        </div>
    );
}

export default NotFoundPage;
