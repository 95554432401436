// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer {
    margin-top: 20px;
    width: 100%;
    height: 160px;
    background-color: #e0e0e0;
}

#footer #footer-bar {
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100px;
    width: max-content;
}

#footer .footer-block {
    float: left;
    margin-left: 40px;
    margin-right: 40px;
    height: 100px;
    width: max-content;
    color: #404040;
    font-size: 13px;
    font-family: roboto;
}

#footer .footer-block-name {
    float: left;
    padding-top: 17px;
    padding-bottom: 17px;
    height: 76px;
    width: max-content;
}

#footer .footer-block-content {
    float: left;
    padding: 15px;
    height: 80px;
}

#footer .footer-block-line {
    margin-bottom: 10px;
    height: 16px;
    line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,oBAAoB;IACpB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":["#footer {\n    margin-top: 20px;\n    width: 100%;\n    height: 160px;\n    background-color: #e0e0e0;\n}\n\n#footer #footer-bar {\n    margin: auto;\n    padding-top: 30px;\n    padding-bottom: 30px;\n    height: 100px;\n    width: max-content;\n}\n\n#footer .footer-block {\n    float: left;\n    margin-left: 40px;\n    margin-right: 40px;\n    height: 100px;\n    width: max-content;\n    color: #404040;\n    font-size: 13px;\n    font-family: roboto;\n}\n\n#footer .footer-block-name {\n    float: left;\n    padding-top: 17px;\n    padding-bottom: 17px;\n    height: 76px;\n    width: max-content;\n}\n\n#footer .footer-block-content {\n    float: left;\n    padding: 15px;\n    height: 80px;\n}\n\n#footer .footer-block-line {\n    margin-bottom: 10px;\n    height: 16px;\n    line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
