/* [COMPONENT] Header */

import React from "react";
import { NavLink as Link } from "react-router-dom";

// CSS
import "../styles/header.css"

function Navblock(props) {
    return (
        <div className={props.active ? "navblock active" : "navblock"}>
            <Link to={props.link}>
                <div>{props.content}</div>
            </Link>
        </div>
    );
}

function Header(props) {
    let navbar = props.blocks.map((block, index) => {
        return (
            <Navblock
                key={index.toString()}
                link={block.link}
                content={block.content}
                active={index === props.current}
            />
        );
    });

    return (
        <section id="header">
            <div className="logo">
                <Link to="/"><img src={process.env.PUBLIC_URL + "/images/logo-transparent.png"} alt="logo"></img></Link>
            </div>
            <div id="navbar">{navbar}</div>
        </section>
    );
}

export default Header;
