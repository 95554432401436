// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/fonts/roboto/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/fonts/roboto/Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/fonts/roboto/Roboto-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../public/fonts/roboto/Roboto-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../public/fonts/titillium-web/TitilliumWeb-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: roboto;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}


@font-face {
    font-family: roboto-bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: roboto-black;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
    font-family: roboto-italic;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
    font-family: titillium;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,4CAAsD;AAC1D;;;AAGA;IACI,wBAAwB;IACxB,4CAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,4CAAoD;AACxD;;AAEA;IACI,0BAA0B;IAC1B,4CAAqD;AACzD;;AAEA;IACI,sBAAsB;IACtB,4CAAmE;AACvE","sourcesContent":["@font-face {\n    font-family: roboto;\n    src: url(../../public/fonts/roboto/Roboto-Regular.ttf);\n}\n\n\n@font-face {\n    font-family: roboto-bold;\n    src: url(../../public/fonts/roboto/Roboto-Bold.ttf);\n}\n\n@font-face {\n    font-family: roboto-black;\n    src: url(../../public/fonts/roboto/Roboto-Black.ttf);\n}\n\n@font-face {\n    font-family: roboto-italic;\n    src: url(../../public/fonts/roboto/Roboto-Italic.ttf);\n}\n\n@font-face {\n    font-family: titillium;\n    src: url(../../public/fonts/titillium-web/TitilliumWeb-Regular.ttf);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
