// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
    margin-bottom: 32px;
    width: 100%;
    height: 80px;
}

#header a {
    text-decoration: none;
}

#header .logo {
    float: left;
    margin-left: 40px;
    padding: 15px;
    height: 50px;
}

#header .logo img {
    height: 100%;
}

#header #navbar {
    margin: auto;
    width: max-content;
    height: 100%;
}

#header .navblock {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: 180px;
    height: 80px;
}

#header .navblock a {
    border-radius: 35px;
}

#header .navblock div {
    padding: 20px;
    width: 130px;
    height: 30px;
    border-radius: 35px;
    line-height: 30px;
    text-align: center;
    font-size: 28px;
    font-family: roboto-bold;
    color: #808080;
    transition: background-color 0.3s;
}

#header .navblock.active div {
    color: #004aad;
}

#header .navblock div:hover {
    background-color: #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#header {\n    margin-bottom: 32px;\n    width: 100%;\n    height: 80px;\n}\n\n#header a {\n    text-decoration: none;\n}\n\n#header .logo {\n    float: left;\n    margin-left: 40px;\n    padding: 15px;\n    height: 50px;\n}\n\n#header .logo img {\n    height: 100%;\n}\n\n#header #navbar {\n    margin: auto;\n    width: max-content;\n    height: 100%;\n}\n\n#header .navblock {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    float: left;\n    width: 180px;\n    height: 80px;\n}\n\n#header .navblock a {\n    border-radius: 35px;\n}\n\n#header .navblock div {\n    padding: 20px;\n    width: 130px;\n    height: 30px;\n    border-radius: 35px;\n    line-height: 30px;\n    text-align: center;\n    font-size: 28px;\n    font-family: roboto-bold;\n    color: #808080;\n    transition: background-color 0.3s;\n}\n\n#header .navblock.active div {\n    color: #004aad;\n}\n\n#header .navblock div:hover {\n    background-color: #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
