// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#not-found .message-box {
    margin: auto;
    width: 1200px;
    height: 560px;
}

#not-found .message {
    margin: auto;
    padding-top: 180px;
    width: max-content;
    font-size: 96px;
    font-family: titillium;
    font-weight: 700;
}

#not-found .highlight {
    color: #004aad;
}
`, "",{"version":3,"sources":["webpack://./src/styles/not-found.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#not-found .message-box {\n    margin: auto;\n    width: 1200px;\n    height: 560px;\n}\n\n#not-found .message {\n    margin: auto;\n    padding-top: 180px;\n    width: max-content;\n    font-size: 96px;\n    font-family: titillium;\n    font-weight: 700;\n}\n\n#not-found .highlight {\n    color: #004aad;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
